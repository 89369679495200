import style from "styled-components";

const StyledModalContent = style.div`
  .modalHeader {
    color: ${(props) => props.theme.colors.primary.main};
    font-size: 28px;
  }

  .modalContent {
    font-family: Spoqa Han Sans;
    margin: 20px 0 40px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.8;

    strong {
      font-weight: 600;
    }
  }
`;

export default StyledModalContent;
