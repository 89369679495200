import style from "styled-components";
import { PALETTE } from "../../../constants/Colors";

export const StyledInputBase = style.input`
  ${(props) => {
    const { variant } = props;
    const base = {
      "box-sizing": "border-box",
      width: "100%",
      "min-width": "3.4rem",
      padding: "1rem",
      "border-radius": "0.4rem",
      outline: "none",
      "font-size": "1.2rem",
    };

    switch (variant) {
      case "outlined":
        return {
          ...base,
          border: `solid 0.1rem ${PALETTE.GREY[100]}`,
          "background-color": "#ffffff",
          "&:focus": {
            border: `solid 0.1rem ${props.theme.colors.primary.main}`,
          },
        };
      case "transparent":
      default:
        return { ...base, border: "none", "background-color": "transparent" };
    }
  }}
`;

export const StyledInputLabel = style.label`
  line-height: 2;
  font-size: 1.4rem;
  color: ${PALETTE.GREY[700]};
  font-weight: 500;
`;
