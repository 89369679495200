import React from "react";
import style from "styled-components";
import { ThemeProvider } from "styled-components";
import axios from "axios";

import "./App.css";
import { Grid, GridItem } from "./components/UI/Grid";
import Input from "./components/UI/Input";
import { Theme } from "./styles/Theme";
import GlobalStyle from "./styles/globalStyles";
import { PALETTE } from "./constants/Colors";
import Button from "./components/UI/Button";
import Modal from "./components/UI/Modal";
import Card from "./components/UI/Card";
import { Countries, States } from "./constants/Countries";
import AutoCompleteSelect from "./components/AutoCompleteSelect";
import { ContentsPerId } from "./constants/ContentsPerId";

const StyledApp = style.div`
  width: 120rem;
  margin: 0 auto;
  padding-top: 1.2rem;
  min-height: 100vh;
  position: relative;


  ${(props) => props.theme.respondTo.tabLand`
    width: 90%;
  `}



  @media only screen and (max-height:800px) {
    min-height:800px;
    padding:10px 0px;
  },
  
  
  .logo {
    height: 3.2rem;
   
  }

  .wrapper {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .leftWrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 50rem;
    box-sizing: border-box;
    font-size: 2rem;
    ${(props) => props.theme.respondTo.phoneLand`
      height: auto;
      max-width: initial;
    `};
    
    .title {
      font-size: 4rem;
      font-weight: 600;
      line-height: 1.2;
      color: ${(props) => props.theme.colors.primary.main}
    }

    .content {
      font-family: sofia-pro;
      font-weight: 300;
      color: #444;
      margin-top: 3.2rem;
    }
  }

  .rightWrapper {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${(props) => props.theme.respondTo.phoneLand`
      height: auto;
    `};
  }

  .inputCard {
    margin-top: 2rem;
    margin-left: -1rem;
    margin-right: 1rem;
    width: 100%;
  }
  
  .shadowCard {
    position: absolute;
    height: calc(100% - 2rem);
    left: 1rem;
    top: 0;
    bottom: 2rem;
    z-index: -1;
  }

  .inputTitle {
    font-size: 3.2rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
    color: ${PALETTE.GREY[700]}
  }

  .inputRow {
    width: 100%;
    margin-bottom: 0.8rem;
    position: relative;

    .hiddenInput {
      opacity: 0;
      width: 100%;
      height: 0;
      position: absolute;

    }
  }

  .buttonRow {
    width: 100%;
    text-align: center;
    margin-top: 2.4rem;
  }
`;

const App = ({ match }) => {
  const [country, setCountry] = React.useState("");
  const [state, setState] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const params = new URLSearchParams(window.location.search);
  let id = match.params.id;
  if (!id) {
    id = params.get('id');
  }
  const urlEntries = params.entries();
  const utmParams = (() => {
    let result = {};
    for (let entry of urlEntries) {
      const [key, value] = entry;
      if (key.includes("utm")) {
        result[key] = value;
      }
    }
    return result;
  })();

  const usa = "United States";
  const contentsFactory = ContentsPerId.find((content) => content.id === id);
  if (!contentsFactory) window.location.replace("https://www.superb-ai.com/");

  const handleChangeCountry = (value) => {
    setCountry(value.value);
  };

  const handleChangeState = (value) => {
    setState(value.value);
  };

  const sendEmail = (e) => {
    if (e) e.preventDefault();
    const elements = e.target.elements;
    const lastName = elements["lastName"].value;
    const firstName = elements["firstName"].value;
    const email = elements["email"].value;
    const job = elements["job"].value;
    const company = elements["company"].value;
    const country = elements["country"].value;
    const state = country === usa ? elements["state"].value : "";
    const params = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      job: job,
      company: company,
      country: country,
      state: state,
    };

    setEmail(email);

    elements["lastName"].value = "";
    elements["firstName"].value = "";
    elements["email"].value = "";
    elements["job"].value = "";
    elements["company"].value = "";
    elements["country"].value = "";
    if (elements["state"]) elements["state"].value = "";
    if (window.analytics) {
      window.analytics.identify(
        email,
        {
          email,
          firstName,
          lastName,
          company,
          position: job,
          country,
          state,
          // source: "whitePaper_1",
          formSubmit: "WHITEPAPER_1_DOWNLOAD",
          ...utmParams,
        },
        {
          integrations: {
            Salesforce: true,
            Intercom: false,
            Mailchimp: {
              subscriptionStatus: "subscribed",
            },
          },
        }
      );

      window.analytics.track("whitepaper_download", {
        category: "download_page",
        label: "white paper #1",
      });
    }
    setIsModalOpen("success");
  };

  const handleClickOkayOnModal = () => {
    window.location.href = "https://blog.superb-ai.com/";
    setIsModalOpen(false);
  };
  if (id === 'training_data_platform_benefits_download') {
    return (<></>)
    //id가 저거일 떄, 잠깐 폼이 나오는 문제가 있어서 추가한 코드입니다.
    // 하얀색 화면만 표시되도록 return을 이렇게 처리했습니다.
    // 코드를 더럽게 해서 죄송합니다. 서희님
  }
  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <StyledApp>
        <Modal
          isOpen={!!isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
        >
          {isModalOpen === "success" && (
            <>
              <div className="modalHeader"></div>
              <div className="modalHeader">Thanks!</div>
              <div className="modalContent">
                You’ll receive a download link shortly.
              </div>
              <Button
                variant="squared"
                size="small"
                onClick={handleClickOkayOnModal}
              >
                Okay
              </Button>
            </>
          )}
          {isModalOpen === "error" && (
            <>
              <div className="modalHeader">
                <span role="img" aria-label="success">
                  🤔
                </span>
              </div>
              <div className="modalHeader">Sorry</div>
              <div className="modalContent">
                We've encountered an Error.
                <br />
                Please try again.
              </div>
              <Button
                variant="squared"
                size="small"
                onClick={() => setIsModalOpen(false)}
              >
                Okay
              </Button>
            </>
          )}
        </Modal>

        <a className="logoLink" href="https://www.superb-ai.com/">
          <div>
            <img
              className="logo"
              src="https://asset.superb-ai.com/assets/img/new_logo.png"
              alt="logo"
            />
          </div>
        </a>
        <div className="wrapper">
          <Grid respondPhoneLand={{ "flex-direction": "column" }}>
            <GridItem respondPhoneLand spacing="2rem">
              <div className="leftWrapper">{contentsFactory.contents}</div>
            </GridItem>
            <GridItem respondPhoneLand spacing="2rem">
              <div className="rightWrapper">
                <Card className="inputCard">
                  <div className="inputTitle">Sign up for free download</div>
                  <form onSubmit={sendEmail}>
                    <div className="inputRow">
                      <Input
                        required
                        label="First name *"
                        variant="outlined"
                        placeholder="Johnny"
                        name="firstName"
                        type="text"
                      />
                    </div>
                    <div className="inputRow">
                      <Input
                        required
                        label="Last name *"
                        variant="outlined"
                        placeholder="Appleseed"
                        name="lastName"
                        type="text"
                      />
                    </div>
                    <div className="inputRow">
                      <Input
                        required
                        label="Work Email *"
                        variant="outlined"
                        placeholder="yourname@example.com"
                        name="email"
                        type="email"
                      />
                    </div>
                    <div className="inputRow">
                      <Input
                        required
                        label="Job Title *"
                        variant="outlined"
                        name="job"
                        type="text"
                      />
                    </div>
                    <div className="inputRow">
                      <Input
                        required
                        label="Company Name *"
                        variant="outlined"
                        name="company"
                        type="text"
                      />
                    </div>
                    <div className="inputRow">
                      <AutoCompleteSelect
                        required
                        label="Country *"
                        name="country"
                        options={Countries}
                        onChange={handleChangeCountry}
                      ></AutoCompleteSelect>
                      <input
                        className="hiddenInput"
                        value={country}
                        onChange={() => { }}
                        required
                      />
                    </div>
                    {country === usa && (
                      <div className="inputRow">
                        <AutoCompleteSelect
                          required
                          label="State *"
                          name="state"
                          options={States}
                          onChange={handleChangeState}
                        />
                        <input
                          className="hiddenInput"
                          value={state}
                          onChange={() => { }}
                          required
                        />
                      </div>
                    )}
                    <div className="buttonRow">
                      <Button>GET THE PAPER</Button>
                    </div>
                  </form>
                </Card>
                <Card
                  className="shadowCard"
                  background={`linear-gradient(43deg, ${PALETTE.RED[600]}, ${PALETTE.ORANGE[600]} 100%)`}
                />
              </div>
            </GridItem>
          </Grid>
        </div>
      </StyledApp>
    </ThemeProvider>
  );
};
export default App;
