import { createGlobalStyle } from "styled-components";

import { respondTo } from "./abstract/_mixins";

const GlobalStyle = createGlobalStyle`
    *,
    *::after,
    *::before {
        margin: 0;
        padding: 0;
        box-sizing: inherit;
    }
    
    html {
        /* 1 rem = 10px, 10px/16px = 62.5% */
        font-size: 62.5%; 
        font-family: 'sofia-pro';

        /* 1 rem = 9px, 9/16 = 56.25% */
        ${respondTo.tabLand`
            font-size: 56.25%; 
        `}

        /* 1 rem = 8px, 8/16 = 50% */
        ${respondTo.tabPort`
            font-size: 50%; 
        `}

        /* 1 rem = 6px, 6/16 = 37.5% */
        ${respondTo.phoneLand`
        font-size: 37.5%;
        `}
    }
`;

export default GlobalStyle;
