import React from "react";
import StyledCard from "./style";

const Card = (props) => {
  const {
    children,
    width = "100%",
    maxWidth = "initial",
    align = "initial",
    padding = "4rem 8rem",
    clickable = false,
    background = "#fff",
    onClick = () => {},
    className,
  } = props;

  return (
    <StyledCard
      className={className}
      clickable={clickable}
      width={width}
      maxWidth={maxWidth}
      align={align}
      padding={padding}
      onClick={onClick}
      background={background}
    >
      {children}
    </StyledCard>
  );
};

export default Card;
