import React from "react";

export const ContentsPerId = [
  {
    id: "training_data_platform_benefits",
    contents: (
      <>
        <div className="title">
          What Are The Benefits Of
          <br />A Training Data Platform?
        </div>
        <div className="content">
          Proper training data management involves continuous updates, constant
          quality control, cross-functional collaboration, workflow integrations
          - the list goes on.
          <br />
          <br />
          <strong>Hyun Kim, Superb AI’s CEO</strong>, talks about the importance
          of a training data platform and how choosing the right one can
          accelerate your AI development cycle.
        </div>
      </>
    ),
  },
  {
    id: "training_data_platform_benefits_download",
    contents: (<></>)
  }
];
