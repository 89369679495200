import styled from "styled-components";

const StyledCard = styled.div`
  cursor: ${(props) => (props.clickable ? "pointer" : "initial")};
  box-sizing: border-box;
  background: ${(props) => props.background};
  border-radius: 0.8rem;
  padding: ${(props) => props.padding};
  ${(props) => props.theme.respondTo.phonePort`
    padding: 4rem;
  `};
  display: block;
  text-align: ${(props) => props.align};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

export default StyledCard;
