import React from "react";
import { StyledGrid, StyledGridItem } from "./style";

export const Grid = (props) => {
  const {
    children,
    background = "inherit",
    respondPhoneLand,
    respondPhonePort,
    respondTabPort,
  } = props;

  return (
    <StyledGrid
      background={background}
      respondPhonePort={respondPhonePort}
      respondPhoneLand={respondPhoneLand}
      respondTabPort={respondTabPort}
    >
      {children}
    </StyledGrid>
  );
};

export const GridItem = (props) => {
  const {
    children,
    flex = "1",
    background = "inherit",
    respondPhoneLand,
    respondPhonePort,
    respondTabPort,
    spacing = 0
  } = props;
  return (
    <StyledGridItem
      flex={flex}
      background={background}
      respondPhonePort={respondPhonePort}
      respondPhoneLand={respondPhoneLand}
      respondTabPort={respondTabPort}
      spacing={spacing}
    >
      {children}
    </StyledGridItem>
  );
};
