import style from "styled-components";

export const StyledButton = style.button`
  padding: ${(props) =>
    props.size === "medium" ? "1.4rem 4.4rem" : "1.4rem 3.2rem"};
  border-radius: ${(props) => (props.variant === "rounded" ? "10rem" : "4px")};
  outline: none;
  border: none;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: ${(props) => props.theme.colors.primary.main};
  color: ${(props) => props.theme.colors.primary.contrastText};
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary.dark};
  }
`;
