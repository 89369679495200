import { PALETTE } from "../../constants/Colors";

export const ColorTheme = {
  primary: {
    light2: PALETTE.RED[50],
    light: PALETTE.RED[100],
    main: PALETTE.RED[600],
    dark: PALETTE.RED[800],
    contrastText: "#ffffff",
  },
  secondary: {
    light: PALETTE.BLUE[100],
    main: PALETTE.BLUE[600],
    contrastText: "#ffffff",
  },
};

export const shadows = [
  "2px 2px 6px rgba(255, 98, 90, 0.1)",
  "0px 2px 10px rgba(0, 0, 0, 0.06)",
];

export const breakpoints = {
  phonePort: "37.5em", //600px
  phoneLand: "53.75em", //860px
  tabPort: "62.5em", //1000px
  tabLand: "75em", //1200px
  bigDesktop: "112.5em", //1800px
};
