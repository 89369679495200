import React from "react";
import { StyledButton } from "./style";

export default (props) => {
  const { children, variant = "rounded", size = "medium", onClick } = props;
  return (
    <StyledButton variant={variant} size={size} onClick={onClick}>
      {children}
    </StyledButton>
  );
};
