import React from "react";
import { StyledInputLabel, StyledInputBase } from "./style";

export default (props) => {
  const { label, variant = "transparent", ...otherProps } = props;

  return (
    <>
      <StyledInputLabel>{label}</StyledInputLabel>
      <StyledInputBase variant={variant} {...otherProps} />
    </>
  );
};
