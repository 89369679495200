import React from "react";
import Modal from "react-modal";
import StyledModalContent from "./style";

const index = (props) => {
  const { isOpen, onRequestClose, children } = props;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "400px",
      border: "none",
      padding: "50px",
      textAlign: "center",
      borderRadius: "8px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
    },
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
    >
      <StyledModalContent>{children}</StyledModalContent>
    </Modal>
  );
};

export default index;
