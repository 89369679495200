import React from "react";
import Select from "react-select";
import { StyledAutoCompleteSelect } from "./style";
import { StyledInputLabel } from "../UI/Input/style";

const AutoCompleteSelectTheme = (theme) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: "rgba(0, 0, 0, 0.3)",
      primary25: "rgba(0, 0, 0, 0.14)",
      primary50: "rgba(0, 0, 0, 0.14)",
    },
  };
};

const index = (props) => {
  const { label, ...selectProps } = props;
  return (
    <>
      <StyledInputLabel>{label}</StyledInputLabel>
      <StyledAutoCompleteSelect>
        <Select
          {...selectProps}
          isSearchable
          theme={(theme) => AutoCompleteSelectTheme(theme)}
          classNamePrefix="autocomplete_select"
        />
      </StyledAutoCompleteSelect>
    </>
  );
};

export default index;
