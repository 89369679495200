import Color from "color";
import _ from "lodash";

export const PALETTE = {
  RED: {
    50: "#FFF6F6",
    100: "#FFECEC",
    200: "#FFDADA",
    300: "#FFBCBC",
    400: "#FF9EA7",
    500: "#FF828A",
    600: "#FF625A",
    700: "#FF4539",
    800: "#E53D30",
    900: "#C23C33",
  },
  ORANGE: {
    50: "#FFF8F4",
    100: "#FFF4ED",
    200: "#FFEADD",
    300: "#FFDBC5",
    400: "#FDCDB0",
    500: "#FFB692",
    600: "#FE9573",
    700: "#FF8041",
    800: "#FF681D",
    900: "#E65400",
  },
  BLUE: {
    50: "#F3F5FF",
    100: "#E6EBFF",
    200: "#D6DEFD",
    300: "#BECAFC",
    400: "#A3B6FE",
    500: "#7691FF",
    600: "#5A7BFF",
    700: "#2A42E4",
    800: "#1226AA",
    900: "#0F206C",
  },
  GREY: {
    50: "#FBFBFB",
    60: "#F3F3F3",
    80: "#E5E5E5",
    100: "#D5D5D5",
    200: "#BCBCBC",
    300: "#ADADAD",
    400: "#929292",
    500: "#7B7B7B",
    600: "#6F6F6F",
    700: "#555555",
    800: "#333333",
    900: "#101010",
  },
};

export const COLOR_CHIPS = (() => {
  const MAIN = {
    CORAL: "#FE9573",
    STRAWBERRY: "#FF625A",
    CARROT: "#FFAF5A",
    YELLOW: "#FFCC00",
    LIME: "#DEF00F",
    GREEN: "#A3EB57",
    MINT: "#4AE2B9",
    SKYBLUE: "#72BBFF",
    COBALT: "#5A7BFF",
    VIOLET: "#6648FF",
    PURPLE: "#AF48FF",
    PINK: "#FF4881",
    CLOUD: "#A6A6A6",
    WHITE: "#FFF",
  };
  const OPACITY_30 = _.map(MAIN, (color) => Color(color).alpha(30));

  return { MAIN, OPACITY_30 };
})();
