import style from "styled-components";
import { PALETTE } from "../../constants/Colors";

export const StyledAutoCompleteSelect = style.div`
  font-size: 1.2rem;

  .autocomplete_select__control {
    &.autocomplete_select__control--is-focused {
      border-color: ${(props) => props.theme.colors.primary.main};
      box-shadow: none;
    }
    .react-select__option {
      &:hover {
        border-color: ${PALETTE.GREY[50]};
      }
    }
  }
`;
