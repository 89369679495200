import styled from "styled-components";

export const StyledGrid = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  ${(props) => {
    if (props.respondTabPort) {
      return props.theme.respondTo.tabPort`
        ${{ ...props.respondTabPort }}
      `;
    }
    if (props.respondPhoneLand) {
      return props.theme.respondTo.phoneLand`
      ${{ ...props.respondPhoneLand }}
      `;
    }
    if (props.respondPhonePort) {
      return props.theme.respondTo.phonePort`
      ${{ ...props.respondPhonePort }}
      `;
    }
  }}
`;

export const StyledGridItem = styled.div`
  flex: ${(props) => props.flex};
  ${(props) => {
    if (props.respondTabPort) {
      return props.theme.respondTo.tabPort`
        order: ${props.respondTabPort};
      `;
    }
    if (props.respondPhoneLand > 0) {
      return props.theme.respondTo.phoneLand`
        order: ${props.respondPhoneLand};
      `;
    }
    if (props.respondPhonePort) {
      return props.theme.respondTo.phonePort`
      ${{ ...props.respondPhonePort }}
      `;
    }
  }}
  &:not(:last-child) {
    margin-right: ${(props) => props.spacing};
    ${(props) => props.theme.respondTo.tabPort`
      margin-right: ${props.spacing};
    `}

    ${(props) => {
      if (props.respondTabPort) {
        return props.theme.respondTo.tabPort`
          margin-right: 0;
          margin-bottom: ${props.spacing};
        `;
      }
      if (props.respondPhoneLand > 0) {
        return props.theme.respondTo.phoneLand`
          margin-right: 0;
          margin-bottom: ${props.spacing};
        `;
      }
      if (props.respondPhonePort > 0) {
        return props.theme.respondTo.phonePort`
          margin-right: 0;
          margin-bottom: ${props.spacing};
        `;
      }
    }}
  }
`;
